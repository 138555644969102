/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { formatDateToYYYYMMDD } from '../../Utils/Time'
import { Box, Button, Card, Stack, Table, TableBody, TableContainer, TextField, Typography } from '@mui/material'
import DrawerNav from '../DrawerNav/DrawerNav'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'

import { useLocation } from 'react-router-dom'
function useQuery() {
    return new URLSearchParams(useLocation().search)
}

function SearchQuestion() {
    const [searchText, setSearchText] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const navigate = useNavigate()

    const handleSearch = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/search/v1?text=${searchText}`)
            if (response.ok) {
                const data = await response.json()
                console.log(' DATA : ', data)
                setSearchResults(data.data.questionsList)
            } else {
                throw new Error('Failed to fetch questions')
            }
        } catch (error) {
            console.error('Error fetching search results:', error)
        }
    }

    const { id } = useParams()
    const query = useQuery()
    const topicName = query.get('name')
    const subjectID = query.get('subjectID')
    const subjectName = query.get('subjectName')
    const [apiCalled, setApiCalled] = useState(false)
    const [examsList, setExamsList] = useState([])

    const sectionsList = ['English', 'Grammar', 'English Exam', 'English Verbs']

    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const [searchQuery, setSearchQuery] = useState('')
    const [filteredTopics, setFilteredTopics] = useState([])

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredTopics(examsList)
        } else {
            setFilteredTopics(examsList.filter((topic) => topic.name.toLowerCase().includes(searchQuery.toLowerCase()) || (topic.description && topic.description.toLowerCase().includes(searchQuery.toLowerCase()))))
        }
    }, [searchQuery, examsList])

    const handleEditQuestion = (id) => {
        navigate('/edit-question?id=' + id)
    }

    const handleDeleteQuestion = (id, question) => {
        navigate('/delete-question?id=' + id + `&question=${question}`)
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <div className='pb-48'>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' mb={5}>
                            <Typography variant='h4' sx={{ fontWeight: 500 }}>
                                Search Question
                            </Typography>
                            <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <TextField label='Search' sx={{ mr: 4, width: '400px' }} value={searchText} onChange={(e) => setSearchText(e.target.value)} variant='outlined' />
                                <Button variant='contained' size='large' onClick={handleSearch}>
                                    Search
                                </Button>
                            </Stack>
                        </Stack>

                        <Card>
                            <TableContainer sx={{ overflow: 'unset' }}>
                                <Table sx={{ width: '100%' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Question</TableCell>
                                            <TableCell>Answer</TableCell>
                                            <TableCell key={9}>
                                                <Typography variant='subtitle1'>Edit</Typography>
                                            </TableCell>
                                            <TableCell key={10}>
                                                <Typography variant='subtitle1'>Delete</Typography>
                                            </TableCell>
                                            <TableCell>Added</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {searchResults.length > 0 &&
                                            searchResults.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell sx={{ width: '30%' }}>{row.question}</TableCell>
                                                    <TableCell>{row.correctOption}</TableCell>
                                                    <TableCell onClick={() => handleEditQuestion(row._id)}>
                                                        <Box component='img' alt='Edit Icon' src='/navbar/ic_pencil.svg' sx={{ height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} />
                                                    </TableCell>
                                                    <TableCell onClick={() => handleDeleteQuestion(row._id, row.question)}>
                                                        <Box component='img' alt='Edit Icon' src='/navbar/ic_trash.svg' sx={{ height: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} />
                                                    </TableCell>
                                                    <TableCell>{formatDateToYYYYMMDD(row.createdAt)}</TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default SearchQuestion