import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Button, TextField, Typography } from '@mui/material'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../DrawerNav/DrawerNav'

export default function EditResource() {
    const location = useLocation()
    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const resourceID = searchParams.get('id')
    const action = searchParams.get('action')
    const resourceType = searchParams.get('type')
    const resourceNameValue = searchParams.get('name')
    const resourceDescriptionValue = searchParams.get('description')
    const resourceImageURLValue = searchParams.get('imageURL')
    const [name, setName] = useState(resourceNameValue)
    const [description, setDescription] = useState(resourceDescriptionValue)
    const [imageURL, setImageURL] = useState(resourceImageURLValue)

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/v1/${resourceType}/${resourceID}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name,
                    description,
                    imageURL
                })
            })
            if (response.ok) {
                console.log('Subject modified successfully')
                setName('')
                navigate(-1)
            } else {
                throw new Error('Failed to modify subject')
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleDeleteTopic = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/v1/${resourceType}/${resourceID}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (response.ok) {
                navigate(-1)
            } else {
                throw new Error('Failed to modify subject')
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getOriginalText = (str) => {
        try {
            if (str === 'exam') {
                return 'Quiz'
            } else if (str === 'subject') {
                return 'Subject'
            }
        } catch {
            return str
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ marginY: 2 }}>
                        Edit {getOriginalText(resourceType)}
                    </Typography>
                    {action === 'edit' && (
                        <>
                            <TextField label='Name' value={name} onChange={(event) => setName(event.target.value)} fullWidth variant='outlined' margin='normal' />
                            <TextField label='Description' value={description} onChange={(event) => setDescription(event.target.value)} fullWidth variant='outlined' margin='normal' />
                            <TextField label='Image URL' value={imageURL} onChange={(event) => setImageURL(event.target.value)} fullWidth variant='outlined' margin='normal' />
                            <Button sx={{ mt: 2 }} variant='contained' color='primary' onClick={handleSaveChanges}>
                                Save Changes
                            </Button>
                        </>
                    )}
                    {action === 'delete' && (
                        <>
                            <TextField label='Name' disabled value={name} onChange={(event) => setName(event.target.value)} fullWidth variant='outlined' margin='normal' />
                            <Button sx={{ mt: 2 }} variant='contained' color='error' onClick={handleDeleteTopic}>
                                Delete
                            </Button>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
