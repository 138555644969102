import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import DrawerNav from '../DrawerNav/DrawerNav';
import { get } from '../../Api/Service';

function Home() {

    const [questionCount, setQuestionCount] = useState(0);
    const [usersCount, setUsersCount] = useState(0);
    const [quizCount, setQuizCount] = useState(0);
    const [adminCount, setAdminCount] = useState(0);
    const [submissions, setSubmissions] = useState([])
    const [users, setUsers] = useState([])

    useEffect(() => {
        get('/api/dashboard/v1/stats')
            .then((data) => {
                const response = data.data
                setQuestionCount(response.questionCount);
                setUsersCount(response.usersCount);
                setQuizCount(response.quizCount);
                setAdminCount(response.adminCount);
                setSubmissions(response.submissions);
                setUsers(response.users);
            })
            .catch((error) => {
                console.error('There was an error fetching the data!', error);
            });
    }, []);

    const analysisPoints = [
        {
            label: 'Questions',
            value: questionCount,
            icon: 'https://common-icons.s3.ap-south-1.amazonaws.com/attachments/tickets/405420_624084.png'
        },
        {
            label: 'Users',
            value: usersCount,
            icon: 'https://common-icons.s3.ap-south-1.amazonaws.com/attachments/tickets/212209_449602.png',
        },
        {
            label: 'Quizzes',
            value: quizCount,
            icon: 'https://common-icons.s3.ap-south-1.amazonaws.com/attachments/tickets/896099_357473.png'
        },
        {
            label: 'Admin',
            value: adminCount,
            icon: 'https://common-icons.s3.ap-south-1.amazonaws.com/attachments/tickets/619992_693225.png'
        },
    ];

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box sx={{ flexGrow: 1, p: 3 }}>
                    <Typography variant="h4" sx={{ mb: 4 }}>Dashboard</Typography>
                    <Grid container spacing={3}>
                        {analysisPoints.map((point, index) => (
                            <Grid item xs={12} md={3} key={index}>
                                <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <img src={point.icon} alt={point.label} style={{ width: 50, height: 50 }} />
                                    <Typography variant="h6">{point.label}</Typography>
                                    <Typography variant="h4">{point.value}</Typography>
                                </Paper>
                            </Grid>
                        ))}

                        {/* Line Chart */}
                        <Grid item xs={6}>
                            <Paper sx={{ p: 2 }}>
                                <Typography variant="h6" gutterBottom>Quiz Submissions</Typography>
                                <ResponsiveContainer width="100%" height={400}>
                                    <LineChart data={submissions} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="monthYear" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="total" stroke="#8884d8" activeDot={{ r: 8 }} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>

                        {/* Bar Chart */}
                        <Grid item xs={6}>
                            <Paper sx={{ p: 2 }}>
                                <Typography variant="h6" gutterBottom>Users</Typography>
                                <ResponsiveContainer width="100%" height={400}>
                                    <BarChart data={users} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="monthYear" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="total" fill="#8884d8" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}

export default Home;
