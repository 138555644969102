import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Grid, Button, MenuItem, TextField, Typography, FormControl, InputLabel, Select } from '@mui/material'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../DrawerNav/DrawerNav'

export default function EditQuestion() {
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const questionId = searchParams.get('id')
    const [question, setQuestion] = useState(null)
    const [editedQuestion, setEditedQuestion] = useState(null)

    useEffect(() => {
        const fetchQuestionDetails = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/admin/question/${questionId}`)
                if (response.ok) {
                    const data = await response.json()
                    console.log('Data : ', data.data.question)
                    setQuestion(data.data.question)
                    setEditedQuestion(data.data.question)
                    console.log('data.data.question : ', data.data.question)
                } else {
                    throw new Error('Failed to fetch question details')
                }
            } catch (error) {
                console.error(error)
            }
        }

        if (questionId) {
            fetchQuestionDetails()
        }
    }, [questionId])

    const handleInputChange = (event, field) => {
        setEditedQuestion({
            ...editedQuestion,
            [field]: event.target.value
        })
    }

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/question/${questionId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(editedQuestion)
            })
            if (response.ok) {
                console.log('Question updated successfully')
                navigate(-1)
            } else {
                throw new Error('Failed to update question')
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    {editedQuestion && (
                        <>
                            <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                                Edit Question
                            </Typography>
                            <TextField multiline label='Question' value={editedQuestion.question} onChange={(event) => handleInputChange(event, 'question')} fullWidth variant='outlined' margin='normal' />
                            <TextField label='Image URL' value={editedQuestion.imageURL} onChange={(event) => handleInputChange(event, 'imageURL')} fullWidth variant='outlined' margin='normal' />
                            <TextField label='Audio URL' value={editedQuestion.audioURL} onChange={(event) => handleInputChange(event, 'audioURL')} fullWidth variant='outlined' margin='normal' />
                            <Typography variant='body1'>Options:</Typography>
                            <Grid container spacing={0.5}>
                                {editedQuestion.options &&
                                    editedQuestion.options.map((option, index) => (
                                        <Grid item xs={12} md={6} key={index}>
                                            <TextField
                                                value={option}
                                                onChange={(event) => {
                                                    const newOptions = [...editedQuestion.options]
                                                    newOptions[index] = event.target.value
                                                    setEditedQuestion({
                                                        ...editedQuestion,
                                                        options: newOptions
                                                    })
                                                }}
                                                fullWidth
                                                variant='outlined'
                                                margin='normal'
                                                style={{ backgroundColor: option === editedQuestion.correctOption ? '#c0f0c2' : 'white' }}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                            {editedQuestion.options.some((option) => option.trim().length > 0) && (
                                <TextField
                                    select
                                    sx={{ my: 1 }}
                                    name=''
                                    label='Correct Option'
                                    fullWidth
                                    SelectProps={{
                                        native: true
                                    }}
                                    value={editedQuestion.correctOption}
                                    onChange={(event) => handleInputChange(event, 'correctOption')}>
                                    {editedQuestion.options.map(
                                        (option, index) =>
                                            option.trim().length > 0 && (
                                                <option key={index} value={option}>
                                                    {option}
                                                </option>
                                            )
                                    )}
                                </TextField>
                            )}
                            <TextField label='Hint' multiline={true} value={editedQuestion.hint} onChange={(event) => handleInputChange(event, 'hint')} fullWidth variant='outlined' margin='normal' />
                            <TextField label='Explanation' multiline={true} value={editedQuestion.explanation} onChange={(event) => handleInputChange(event, 'explanation')} fullWidth variant='outlined' margin='normal' />
                            <Box mt={2}>
                                <Button variant='contained' color='error' sx={{ marginRight: '10px' }} onClick={() => navigate(-1)}>
                                    Cancel
                                </Button>
                                <Button variant='contained' color='success' onClick={handleSaveChanges}>
                                    Save Changes
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
