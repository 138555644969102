import React, { useState, useEffect } from 'react';
import { Grid, Typography, Avatar, Button } from '@mui/material';
import { Box, Container } from '@mui/system';
import MainLayout from '../DrawerNav/MainLayout';

const Profile = () => {
    const [userData, setUserData] = useState({
        username: '',
        role: '',
    });

    useEffect(() => {
        // Fetch user data from localStorage
        const username = localStorage.getItem('username');
        const role = localStorage.getItem('role') || 'Not Available';

        if (username) {
            setUserData({
                username,
                role,
            });
        }
    }, []);

    const handleLogout = () => {
        localStorage.clear()
        window.location.href = '/login';
    };

    return (
        <MainLayout>
            <Container maxWidth="md">
                <Box
                    sx={{
                        mt: 8,
                        p: 6,
                        boxShadow: 4,
                        borderRadius: 3,
                        bgcolor: '#fff',
                        textAlign: 'center',
                        position: 'relative',
                    }}
                >
                    <Avatar
                        sx={{
                            bgcolor: '#3f51b5',
                            width: 80,
                            height: 80,
                            fontSize: 40,
                            position: 'absolute',
                            top: '-40px',
                            left: '50%',
                            transform: 'translateX(-50%)'
                        }}
                    >
                        {userData.username.charAt(0).toUpperCase()}
                    </Avatar>

                    <Typography variant="h4" sx={{ mt: 5, fontWeight: 'bold' }}>
                        {userData.username}
                    </Typography>

                    <Typography variant="subtitle1" color="textSecondary" sx={{ mb: 4 }}>
                        {userData.role}
                    </Typography>

                    <Box sx={{ textAlign: 'left', mb: 4 }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                            Account Information
                        </Typography>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Box sx={{ p: 2, bgcolor: '#f6f6f6', borderRadius: 2 }}>
                                    <Typography variant="body2" color="textSecondary">Username:</Typography>
                                    <Typography variant="h6">{userData.username}</Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box sx={{ p: 2, bgcolor: '#f6f6f6', borderRadius: 2 }}>
                                    <Typography variant="body2" color="textSecondary">Role:</Typography>
                                    <Typography variant="h6">{userData.role}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Button
                        variant="contained"
                        color="error"
                        sx={{ mt: 2, px: 4, py: 1, fontSize: '1rem', fontWeight: 'bold', borderRadius: 8 }}
                        onClick={handleLogout}
                    >
                        Logout
                    </Button>
                </Box>
            </Container>
        </MainLayout>
    );
};

export default Profile;
