import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Autocomplete, CircularProgress } from '@mui/material';
import DrawerNav from '../DrawerNav/DrawerNav';
import { BASE_URL } from '../../Api/Constants';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function AddExamV2() {
    const [subjects, setSubjects] = useState([]);
    const [topics, setTopics] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [loadingSubjects, setLoadingSubjects] = useState(false);
    const [loadingTopics, setLoadingTopics] = useState(false);
    const [examName, setExamName] = useState('');
    const [examDescription, setExamDescription] = useState('');
    const [examImageURL, setExamImageURL] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSubjects = async () => {
            setLoadingSubjects(true);
            try {
                const response = await fetch(`${BASE_URL}/api/admin/v2/subjects`);
                const data = await response.json();
                if (response.ok) {
                    console.log('Subjects Data: ', data);
                    setSubjects(data.data.list);
                } else {
                    console.error(data.message);
                    toast.error(`Error fetching subjects: ${data.message}`);
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('Error fetching subjects');
            } finally {
                setLoadingSubjects(false);
            }
        };

        fetchSubjects();
    }, []);

    const handleSubjectChange = async (event, newValue) => {
        setSelectedSubject(newValue);
        setSelectedTopic(null);
        if (newValue) {
            setLoadingTopics(true);
            try {
                const response = await fetch(`${BASE_URL}/api/admin/v2/topics?subjectID=${newValue._id}`);
                const data = await response.json();
                if (response.ok) {
                    console.log('Topics Data: ', data);
                    setTopics(data.data.list);
                } else {
                    console.error(data.message);
                    toast.error(`Error fetching topics: ${data.message}`);
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('Error fetching topics');
            } finally {
                setLoadingTopics(false);
            }
        } else {
            setTopics([]);
        }
    };

    const handleSaveChanges = async () => {
        if (!selectedSubject || !selectedTopic) {
            console.error('Subject and Topic are required');
            toast.error('Subject and Topic are required');
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}/api/admin/v1/exam`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    subjectID: selectedSubject._id,
                    topicID: selectedTopic._id,
                    name: examName,
                    description: examDescription,
                    imageURL: examImageURL
                })
            });

            const data = await response.json();

            if (response.ok) {
                setExamName('');
                setExamDescription('');
                setSelectedSubject(null);
                setSelectedTopic(null);
                toast.success('Exam added successfully');
                navigate(-1);
            } else {
                console.error(data.message);
                toast.error(`Error adding exam: ${data.message}`);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('Error adding exam');
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Add New Exam
                    </Typography>
                    <Autocomplete
                        options={subjects}
                        getOptionLabel={(option) => option.name}
                        value={selectedSubject}
                        onChange={handleSubjectChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Subject"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loadingSubjects ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        options={topics}
                        getOptionLabel={(option) => option.name}
                        value={selectedTopic}
                        onChange={(event, newValue) => setSelectedTopic(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Topic"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loadingTopics ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        disabled={!selectedSubject}
                    />
                    <TextField label='Exam Name' value={examName} onChange={(event) => setExamName(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <TextField label='Exam Description' value={examDescription} onChange={(event) => setExamDescription(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <TextField label='Exam Image URL' value={examImageURL} onChange={(event) => setExamImageURL(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <Box mt={2}>
                        <Button variant='contained' color='success' onClick={handleSaveChanges}>
                            Add Exam
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
