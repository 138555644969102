/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { showTimeInAgoFormat } from '../../Utils/Time'
import { Box, Button, Card, CardContent, Snackbar, Stack, Table, TableBody, TextField, Typography } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import DrawerNav from '../DrawerNav/DrawerNav'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Delete, Edit, RemoveRedEye, VisibilityOff } from '@mui/icons-material'
import TableHead from '@mui/material/TableHead'

import { useLocation } from 'react-router-dom'
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#262726',
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

function TopicComponent() {
    const navigate = useNavigate()
    const { id } = useParams()
    const query = useQuery()
    const topicName = query.get('name')
    const subjectID = query.get('subjectID')
    const subjectName = query.get('subjectName')
    const [apiCalled, setApiCalled] = useState(false)
    const [examsList, setExamsList] = useState([])

    const getTopicsList = async () => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }

            await fetch(`${BASE_URL}/api/admin/v1/topic/${id}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.status === 200) {
                        setExamsList(data.data.resourceData.exams)
                        setApiCalled(true)
                    } else {
                        setOpenErrorAlert(true)
                    }
                })
                .catch((error) => {
                    console.log('Error : ', error)
                    setOpenErrorAlert(true)
                    setApiCalled(true)
                })
        } catch {
        } finally {
            setApiCalled(true)
        }
    }

    useEffect(() => {
        if (!apiCalled) {
            getTopicsList()
        }
    }, [])

    const sectionsList = ['English', 'Grammar', 'English Exam', 'English Verbs']

    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const navigateToRoute = () => {
        navigate(`/add-exam?topicID=${id}&subjectID=${subjectID}&subjectName=${subjectName}&topicName=${topicName}`)
    }

    const handleTopicClick = (id, name) => {
        navigate(`/exam/${id}?name=${name}`)
    }

    const handleResourceClick = (id, action, type, name, description, image) => {
        navigate(`/edit-resource?id=${id}&action=${action}&type=${type}&name=${name}&description=${description}&imageURL=${image}`)
    }

    const [searchQuery, setSearchQuery] = useState('')
    const [filteredTopics, setFilteredTopics] = useState([])

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredTopics(examsList)
        } else {
            setFilteredTopics(examsList.filter((topic) => topic.name.toLowerCase().includes(searchQuery.toLowerCase()) || (topic.description && topic.description.toLowerCase().includes(searchQuery.toLowerCase()))))
        }
    }, [searchQuery, examsList])

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <div className='pb-48'>
                        <div className='flex justify-between items-center p-4'>
                            <h1 className='text-4xl font-bold'>{topicName} Quizzes </h1>
                            <div className='space-x-2'>
                                <TextField size='small' variant='outlined' placeholder='Search...' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} sx={{ height: '40px', backgroundColor: 'white' }} />
                                <Button variant='contained' color='primary' onClick={() => navigateToRoute('add-topic')}>
                                    Create Quiz
                                </Button>
                            </div>
                        </div>
                        <Table sx={{ width: '100%', marginBottom: 30, marginTop: 5 }} aria-label='customized table'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell className='rounded-tl-lg' align='left'>
                                        ID
                                    </StyledTableCell>
                                    <StyledTableCell align='left'>Quiz</StyledTableCell>
                                    <StyledTableCell align='left'>Description</StyledTableCell>
                                    <StyledTableCell align='left'>Questions</StyledTableCell>
                                    <StyledTableCell align='left'>Updated</StyledTableCell>
                                    <StyledTableCell align='left'>Created</StyledTableCell>
                                    <StyledTableCell align='left'>Edit</StyledTableCell>
                                    <StyledTableCell align='left'>Delete</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredTopics.length > 0 &&
                                    filteredTopics.map((topic, index) => {
                                        return (
                                            <StyledTableRow key={topic._id} sx={{ border: '1px solid gray' }}>
                                                <StyledTableCell align='left' sx={{ width: '5%'}}>{topic._id}</StyledTableCell>
                                                <StyledTableCell align='left' className='underline cursor-pointer' onClick={() => handleTopicClick(topic._id, topic.name)}>
                                                    {topic.name}
                                                </StyledTableCell>
                                                <StyledTableCell align='left'>{topic.description}</StyledTableCell>
                                                <StyledTableCell align='left'>{topic.questions ? topic.questions.length : 0}</StyledTableCell>
                                                <StyledTableCell align='left'>{showTimeInAgoFormat(topic.updatedAt)}</StyledTableCell>
                                                <StyledTableCell align='left'>{showTimeInAgoFormat(topic.createdAt)}</StyledTableCell>
                                                <StyledTableCell align='left'>
                                                    <Button variant='contained' color='primary' onClick={() => handleResourceClick(topic._id, 'edit', 'exam', topic.name, topic.description, topic.imageURL)} sx={{ mr: 2 }}>
                                                        Edit
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell align='left'>
                                                    <Button variant='contained' color='error' onClick={() => handleResourceClick(topic._id, 'delete', 'exam', topic.name, topic.description, topic.imageURL)}>
                                                        Delete
                                                    </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </div>
                </Box>
            </Box>
        </>
    )
}

const InfoCardComponent = ({ title, createdAt }) => {
    return (
        <>
            <div class='bg-white shadow overflow-hidden sm:rounded-lg my-8'>
                <div class='px-4 py-5 sm:px-6 bg-blue-200'>
                    <h3 class='text-lg leading-6 font-medium'>{title}</h3>
                    <p class='mt-1 max-w-2xl text-sm text-gray-500'>Edit</p>
                </div>
                <div class='border-t border-gray-200'>
                    <dl>
                        <div class='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                            <dt class='text-sm font-medium text-gray-500'>Number of topics</dt>
                            <dd class='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>4</dd>
                        </div>
                        <div class='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                            <dt class='text-sm font-medium text-gray-500'>Number of Exams</dt>
                            <dd class='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>8</dd>
                        </div>
                        <div class='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                            <dt class='text-sm font-medium text-gray-500'>Created At</dt>
                            <dd class='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>{showTimeInAgoFormat(createdAt)}</dd>
                        </div>
                        <div class='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                            <dt class='text-sm font-medium text-gray-500'>Updated At</dt>
                            <dd class='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>{showTimeInAgoFormat(createdAt)}</dd>
                        </div>
                        <div class='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                            <dt class='text-sm font-medium text-gray-500'>Status</dt>
                            <dd class='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>Active</dd>
                        </div>
                    </dl>
                </div>
            </div>
        </>
    )
}

export default TopicComponent
