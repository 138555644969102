import axios from 'axios';
import { BASE_URL } from './Constants';

let token = localStorage.getItem('token');

const apiService = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    },
});

// Function to update the token in axios instance
export const updateToken = () => {
    token = localStorage.getItem('token');
    apiService.defaults.headers['Authorization'] = `Bearer ${token}`;
};

// GET request
export const get = async (url) => {
    updateToken(); // Refresh token before request
    try {
        const response = await apiService.get(url);
        return response.data;
    } catch (error) {
        console.error('GET request failed:', error);
        throw error;
    }
};

// POST request
export const post = async (url, data) => {
    updateToken(); // Refresh token before request
    try {
        const response = await apiService.post(url, data);
        return response.data;
    } catch (error) {
        console.error('POST request failed:', error);
        throw error;
    }
};

// PUT request
export const put = async (url, data) => {
    updateToken(); // Refresh token before request
    try {
        const response = await apiService.put(url, data);
        return response.data;
    } catch (error) {
        console.error('PUT request failed:', error);
        throw error;
    }
};

// DELETE request
export const del = async (url) => {
    updateToken(); // Refresh token before request
    try {
        const response = await apiService.delete(url);
        return response.data;
    } catch (error) {
        console.error('DELETE request failed:', error);
        throw error;
    }
};
