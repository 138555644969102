import * as React from 'react'
import { useEffect } from 'react'
import { Button, Grid, Snackbar, Stack, TextField } from '@mui/material'
import { Box, Container } from '@mui/system'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../../Api/Constants'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const Login = () => {
    const navigate = useNavigate()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const handleLogin = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/admin/v1/login`, {
                username,
                password,
            })

            if (response.data.status === 200) {
                const { token, username, role } = response.data.data
                localStorage.setItem('isAuth', true)
                localStorage.setItem('username', username)
                localStorage.setItem('role', role)
                localStorage.setItem('token', token)

                setOpenSuccessAlert(true)
                navigate('/')
            } else {
                setOpenErrorAlert(true)
            }
        } catch (error) {
            console.error('Login error:', error)
            setOpenErrorAlert(true)
        }
    }

    return (
        <Container maxWidth='sm'>
            <Box sx={{ mt: 8, p: 4, boxShadow: 3, borderRadius: 2, bgcolor: 'background.paper' }}>
                <Grid container spacing={3} alignItems='center' justifyContent='center'>
                    <Grid item xs={12}>
                        <h1 className='text-4xl font-bold text-gray-700 my-5' align='center'>
                            Admin Login
                        </h1>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            placeholder='Enter Username'
                            id='username-input'
                            label='Username'
                            value={username}
                            onChange={(event) => {
                                setUsername(event.target.value)
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            type='password'
                            placeholder='Enter Password'
                            id='password-input'
                            label='Password'
                            value={password}
                            onChange={(event) => {
                                setPassword(event.target.value)
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant='contained'
                            color='success'
                            size='large'
                            onClick={handleLogin}
                            sx={{ mt: 2 }}
                        >
                            Login
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                        Success!!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                        Error!!!
                    </Alert>
                </Snackbar>
            </Stack>
        </Container>
    )
}

export default Login
