import React from 'react'
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom'
import './App.css'
import Home from './Components/Home/Home'
import Header from './Components/Header/Header'
import AddQuestion from './Components/AddQuestion/AddQuestion'
import AddTopic from './Components/AddTopic/AddTopic'
import ReportedQuestions from './Components/ReportedQuestions/ReportedQuestions'
import TopicQuestions from './Components/TopicQuestions/TopicQuestions'
import Login from './Components/V2/Login'
import isAuthenticated from './Auth/AuthenticateUser'
import DeletedTopics from './Components/DeletedTopics/DeletedTopics'
import RecentActivity from './Components/RecentActivity/RecentActivity'
import EditQuestion from './Components/EditQuestion/EditQuestion'
import EditTopic from './Components/EditTopic/EditTopic'
import AddExam from './Components/AddExam/AddExam'
import ViewExams from './Components/ViewExams/ViewExams'
import EditExam from './Components/EditExam/EditExam'
import EditUser from './Components/EditUser/EditUser'
import Users from './Components/JoinedUsers/JoinedUsers'
import ViewTopics from './Components/ViewTopics/ViewTopics'
import AddSubtopic from './Components/AddSubtopic/AddSubtopic'
import ViewSubtopics from './Components/ViewSubtopics/ViewSubtopics'
import EditSubtopic from './Components/EditSubtopic/EditSubtopic'
import ViewSection from './Components/ViewSection/ViewSection'
import ViewSectionTopics from './Components/ViewSectionTopics/ViewSectionTopics'
import QuestionsList from './Components/QuestionsList/QuestionsList'
import DeleteQuestion from './Components/DeleteQuestion/DeleteQuestion'
import AddQuestionWithExcel from './Components/AddQuestionWithExcel/AddQuestionWithExcel'
import EditSubject from './Components/EditSubject/EditSubject'
import SubjectComponent from './Components/SubjectComponent/SubjectComponent'
import TopicComponent from './Components/TopicComponent/TopicComponent'
import ExamComponent from './Components/ExamComponent/ExamComponent'
import ExcelAdd from './Components/ExcelAdd/ExcelAdd'
import EditResource from './Components/EditResource/EditResource'
import TopicManagement from './Components/ManagementTopic/ManagementTopic'
import ReorderQuestions from './Components/ReorderQuestions/ReorderQuestions'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ManagementExam from './Components/ManagementExam/ManagementExam'
import SearchQuestion from './Components/SearchQuestion/SearchQuestion'
import AddTopicV2 from './Components/AddTopicV2/AddTopicV2'
import AddExamV2 from './Components/AddExamV2/AddExamV2'
import ImageUpload from './Components/ImageUpload/ImageUpload'
import GradeExcel from './Components/GradeExcel/GradeExcel'
import AddGrade from './Components/Grade/AddGrade'
import Grades from './Components/Grade/Grades'
import ResourceManagement from './Components/Resource/ResourceManagement'
import AddResource from './Components/Resource/AddResource'
import ResourceList from './Components/Resource/ResourceList'
import ResourceDetails from './Components/Resource/ResourceDetails'
import AdminUsers from './Components/V2/AdminUsers'
import SubjectAllotment from './Components/V2/SubjectAllotment'
import Profile from './Components/V2/Profile'

function App() {
    const PrivateWrapper = () => {
        return isAuthenticated() ? <Outlet /> : <Navigate to='/login' />
    }
    return (
        <BrowserRouter>
            <div>
                <ToastContainer />
                <Header />
                <Routes>
                    <Route exact path='/login' element={<Login />} />
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/' element={<Home />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/profile' element={<Profile />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/management/:resource' element={<ResourceManagement />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/resources/:resource' element={<ResourceList />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit/:resource/:id' element={<ResourceDetails />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add/:resource' element={<AddResource />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-question' element={<AddQuestion />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-excel-questions/:id' element={<AddQuestionWithExcel />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/grade-excel' element={<GradeExcel />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-topic' element={<AddTopic />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-topic-v2' element={<AddTopicV2 />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-grade' element={<AddGrade />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-subtopic' element={<AddSubtopic />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/subjects' element={<ViewTopics />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/section' element={<ViewSection />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/topic' element={<ViewSectionTopics />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/exams' element={<ViewSubtopics />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/questions-list' element={<QuestionsList />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/users' element={<Users />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/topic-questions/:id' element={<TopicQuestions />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/reported-questions' element={<ReportedQuestions />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/deleted-topics' element={<DeletedTopics />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/recent-activity' element={<RecentActivity />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-question' element={<EditQuestion />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-topic' element={<EditTopic />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-subject' element={<EditSubject />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-subtopic' element={<EditSubtopic />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-exam' element={<AddExam />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/add-exam-v2' element={<AddExamV2 />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/view-exams' element={<ViewExams />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/exam' element={<EditExam />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-user' element={<EditUser />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/delete-question' element={<DeleteQuestion />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/subject/:id' element={<SubjectComponent />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/topic/:id' element={<TopicComponent />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/exam/:id' element={<ExamComponent />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/reorder-questions/:id' element={<ReorderQuestions />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/excel-add' element={<ExcelAdd />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/edit-resource' element={<EditResource />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/grade-management' element={<Grades />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/topic-management' element={<TopicManagement />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/exam-management' element={<ManagementExam />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/search-question' element={<SearchQuestion />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/image-upload' element={<ImageUpload />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/admin-users' element={<AdminUsers />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/subject-allotment' element={<SubjectAllotment />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/import-excel' element={<GradeExcel />} />
                    </Route>
                </Routes>
            </div>
        </BrowserRouter>
    )
}

export default App
