import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { FileCopyRounded, Home, Image, LogoutOutlined, PanoramaPhotosphere, Person, Search, Subject, SubjectOutlined, SupervisorAccount, Topic } from '@mui/icons-material'
import GradeExcel from '../GradeExcel/GradeExcel'

function DrawerNav() {
    const navigate = useNavigate()
    const handleRoute = (url) => {
        if (url === 'login') localStorage.clear()
        navigate('/' + url)
    }

    const isMobile = useMediaQuery('(max-width:600px)')

    // Get the user's role from localStorage
    const userRole = localStorage.getItem('role');

    const contestListItems = [
        {
            value: 'Home',
            route: '',
            icon: <Home />
        },
        {
            value: 'Add Grade',
            route: 'add-grade',
            icon: <Topic />
        },
        {
            value: 'Upload Image',
            route: 'image-upload',
            icon: <Image />
        },
        {
            value: 'Import Excel',
            route: 'import-excel',
            icon: <FileCopyRounded />
        }
    ]

    const contestListItems2 = [
        {
            value: 'Grade Management',
            route: 'management/grade',
            icon: <Subject />
        },
        {
            value: 'Subject Management',
            route: 'management/subject',
            icon: <Topic />
        },
        {
            value: 'Term Management',
            route: 'management/term',
            icon: <PanoramaPhotosphere />
        },
        {
            value: 'Topic Management',
            route: 'management/topic',
            icon: <Subject />
        },
        {
            value: 'Quiz Management',
            route: 'management/quiz',
            icon: <Topic />
        },
        {
            value: 'Search Question',
            route: 'search-question',
            icon: <Search />
        }
    ]

    // Conditional rendering of admin-specific items
    const adminListItems = [
        {
            value: 'Admin Users',
            route: 'admin-users',
            icon: <SupervisorAccount />
        },
        {
            value: 'Subject Allotment',
            route: 'subject-allotment',
            icon: <SubjectOutlined />
        }
    ];

    const contestListItems4 = [
        {
            value: 'Profile',
            route: 'profile',
            icon: <Person />
        },
        {
            value: 'Logout',
            route: 'login',
            icon: <LogoutOutlined />
        },
    ]

    const drawerWidth = 300

    return (
        <>
            <CssBaseline />
            <Drawer
                variant='permanent'
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    display: isMobile ? 'none' : 'block',
                    position: 'relative',
                    zIndex: 1
                }}>
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {contestListItems.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {contestListItems2.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List>
                        {userRole === 'admin' && (
                            <>
                                {adminListItems.map((text) => (
                                    <ListItem key={text.value} disablePadding>
                                        <ListItemButton onClick={() => handleRoute(text.route)}>
                                            <ListItemIcon>{text.icon}</ListItemIcon>
                                            <ListItemText primary={text.value} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}</>
                        )}
                        {contestListItems4.map((text) => (
                            <ListItem key={text.value} disablePadding>
                                <ListItemButton onClick={() => handleRoute(text.route)}>
                                    <ListItemIcon>{text.icon}</ListItemIcon>
                                    <ListItemText primary={text.value} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                </Box>
            </Drawer>
        </>
    )
}

export default DrawerNav
