import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Fab,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DrawerNav from '../DrawerNav/DrawerNav';
import { BASE_URL } from '../../Api/Constants';
import { showTimeInAgoFormat } from '../../Utils/Time';

const AdminUsers = () => {
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [newUser, setNewUser] = useState({ username: '', role: 'teacher', password: '' });
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
    const [newPassword, setNewPassword] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/v1/users`);
            const data = await response.json();
            console.log('Data : ', data)
            if (Array.isArray(data.data.list)) {
                setUsers(data.data.list);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditMode(false);
        setNewUser({ username: '', role: 'teacher', password: '' });
        setCurrentUserId(null);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setNewUser((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleAddUser = async () => {
        try {
            const method = editMode ? 'PUT' : 'POST';
            const url = editMode ? `${BASE_URL}/api/admin/v1/user/${currentUserId}` : `${BASE_URL}/api/admin/v1/user`;

            const data = await fetch(url, {
                method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newUser),
            });
            console.log('data : ', data)
            if (data.status === 200) {
                fetchUsers();
                handleClose();
                toast.success(editMode ? 'User updated successfully!' : 'User added successfully!');
            } else {
                toast.error('Something went wrong', {
                    position: 'bottom-left'
                })
            }
        } catch (error) {
            console.error('Error adding/updating user:', error);
            toast.error(editMode ? 'Failed to update user.' : 'Failed to add user.');
        }
    };

    const handleEditUser = (user) => {
        setNewUser({ username: user.username, role: user.role, password: '' });
        setCurrentUserId(user._id);
        setEditMode(true);
        handleOpen();
    };

    const handleDeleteUser = async () => {
        try {
            await fetch(`${BASE_URL}/api/admin/v1/user/${userToDelete._id}`, {
                method: 'DELETE',
            });
            fetchUsers();
            setDeleteConfirmOpen(false);
            toast.success('User deleted successfully!');
        } catch (error) {
            console.error('Error deleting user:', error);
            toast.error('Failed to delete user.');
        }
    };

    const handleOpenDeleteConfirm = (user) => {
        setUserToDelete(user);
        setDeleteConfirmOpen(true);
    };

    const handleCloseDeleteConfirm = () => {
        setDeleteConfirmOpen(false);
        setUserToDelete(null);
    };

    const handleOpenResetPassword = (user) => {
        setCurrentUserId(user._id);
        setResetPasswordOpen(true);
    };

    const handleCloseResetPassword = () => {
        setResetPasswordOpen(false);
        setNewPassword('');
        setCurrentUserId(null);
    };

    const handleResetPassword = async () => {
        try {
            await fetch(`${BASE_URL}/api/admin/v1/reset-password/${currentUserId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ newPassword }),
            });
            handleCloseResetPassword();
            toast.success('Password reset successfully!');
        } catch (error) {
            console.error('Error resetting password:', error);
            toast.error('Failed to reset password.');
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3, color: '#524d4d' }}>
                <Box sx={{ padding: 2 }}>
                    <Table sx={{ minWidth: 650, borderRadius: 2, overflow: 'hidden' }}>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                <TableCell>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                        Username
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                        Role
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                        Created At
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                        Actions
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user) => (
                                <TableRow key={user.username} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' } }}>
                                    <TableCell>{user.username}</TableCell>
                                    <TableCell>{user.role}</TableCell>
                                    <TableCell>{showTimeInAgoFormat(user.createdAt)}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleEditUser(user)} color="primary">
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleOpenDeleteConfirm(user)} color="secondary">
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleOpenResetPassword(user)} color="default">
                                            <LockResetIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={handleOpen}>
                        <AddIcon />
                    </Fab>

                    {/* Add/Edit User Dialog */}
                    <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: 2 } }}>
                        <DialogTitle>{editMode ? 'Edit User' : 'Add New User'}</DialogTitle>
                        <DialogContent>
                            <TextField
                                margin="dense"
                                name="username"
                                label="Username"
                                fullWidth
                                value={newUser.username}
                                onChange={handleChange}
                            />
                            <TextField
                                margin="dense"
                                name="password"
                                label="Password"
                                type="password"
                                fullWidth
                                value={newUser.password}
                                onChange={handleChange}
                                placeholder={editMode ? 'Leave blank to keep current password' : ''}
                            />
                            <FormControl fullWidth margin="dense" sx={{ mt: 2 }}>
                                <InputLabel>Role</InputLabel>
                                <Select
                                    name="role"
                                    value={newUser.role}
                                    onChange={handleChange}
                                    label="Role"
                                >
                                    <MenuItem value="teacher">Teacher</MenuItem>
                                    <MenuItem value="viewer">Viewer</MenuItem>
                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={handleAddUser} color="primary">
                                {editMode ? 'Update User' : 'Add User'}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* Delete Confirmation Dialog */}
                    <Dialog open={deleteConfirmOpen} onClose={handleCloseDeleteConfirm} PaperProps={{ sx: { borderRadius: 2 } }}>
                        <DialogTitle>Confirm Delete</DialogTitle>
                        <DialogContent>
                            <Typography>Are you sure you want to delete this user?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDeleteConfirm} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={handleDeleteUser} color="primary">
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* Reset Password Dialog */}
                    <Dialog open={resetPasswordOpen} onClose={handleCloseResetPassword} PaperProps={{ sx: { borderRadius: 2 } }}>
                        <DialogTitle>Reset Password</DialogTitle>
                        <DialogContent>
                            <TextField
                                margin="dense"
                                name="newPassword"
                                label="New Password"
                                type="password"
                                fullWidth
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseResetPassword} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={handleResetPassword} color="primary">
                                Reset Password
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <ToastContainer />
                </Box>
            </Box>
        </Box>
    );
};

export default AdminUsers;
