import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, InputAdornment, IconButton, Typography, Chip, Button, Modal, Box,
    MenuItem
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MainLayout from '../DrawerNav/MainLayout';
import { BASE_URL } from '../../Api/Constants';

const SubjectAllotment = () => {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [openAssignModal, setOpenAssignModal] = useState(false);
    const [openRemoveModal, setOpenRemoveModal] = useState(false);
    const [selectedRoleId, setSelectedRoleId] = useState(null);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedPermission, setSelectedPermission] = useState(null);

    useEffect(() => {
        fetchSubjectRoles();
    }, []);

    const fetchSubjectRoles = async () => {
        const response = await fetch(`${BASE_URL}/api/admin/v1/roles/subject-roles`);
        const result = await response.json();
        setData(result.data.roles);
        setFilteredData(result.data.roles);
    };

    const handleSearch = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
        const filtered = data.filter((role) =>
            role.name.toLowerCase().includes(term) ||
            role.grade.name.toLowerCase().includes(term)
        );
        setFilteredData(filtered);
    };

    const handleOpenAssignModal = async (roleId) => {
        setSelectedRoleId(roleId);
        await fetchUsers();
        setOpenAssignModal(true);
    };

    const handleCloseAssignModal = () => {
        setOpenAssignModal(false);
        setSelectedUser('');
        setSelectedRoleId(null);
    };

    const handleOpenRemoveModal = (roleId, permission) => {
        setSelectedRoleId(roleId);
        setSelectedPermission(permission);
        setOpenRemoveModal(true);
    };

    const handleCloseRemoveModal = () => {
        setOpenRemoveModal(false);
        setSelectedPermission(null);
        setSelectedRoleId(null);
    };

    const fetchUsers = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/v1/users`);
            const result = await response.json();
            console.log('Result : ', result)
            setUsers(result.data.list);
        } catch (error) {
            console.error('Failed to fetch users', error);
        }
    };

    const handleAssignRole = async () => {
        if (!selectedUser || !selectedRoleId) return;

        const newRole = {
            uid: selectedUser,
            resource: 'subject',
            resourceID: selectedRoleId,
        };

        try {
            const response = await fetch(`${BASE_URL}/api/admin/v1/roles/dashboard-roles`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newRole),
            });

            if (response.ok) {
                console.log('Role assigned or updated successfully');
                fetchSubjectRoles();
                handleCloseAssignModal();
            } else {
                throw new Error('Failed to assign or update role');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleRemovePermission = async () => {
        if (!selectedRoleId || !selectedPermission) return;

        try {
            const response = await fetch(`${BASE_URL}/api/admin/v1/roles/dashboard-roles`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    subjectID: selectedRoleId,
                    username: selectedPermission.username,
                }),
            });

            if (response.ok) {
                console.log('Role deleted successfully');
                fetchSubjectRoles();
                handleCloseRemoveModal();
            } else {
                throw new Error('Failed to delete role');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <MainLayout>
            <Typography variant="h4" sx={{ py: 2 }} gutterBottom>
                Subject Roles
            </Typography>
            <TextField
                label="Search"
                value={searchTerm}
                onChange={handleSearch}
                variant="outlined"
                fullWidth
                sx={{ marginBottom: 2 }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Subject</TableCell>
                            <TableCell>Grade</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Permissions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData.map((role) => (
                            <TableRow key={role._id}>
                                <TableCell>{role.name}</TableCell>
                                <TableCell>{role.grade.name}</TableCell>
                                <TableCell>{role.description}</TableCell>
                                <TableCell>
                                    {role.permissions && role.permissions.map((permission, index) => (
                                        <Chip
                                            key={index}
                                            color="info"
                                            label={permission.username}
                                            sx={{ mr: 0.5, mb: 0.5 }}
                                            onClick={() => handleOpenRemoveModal(role._id, permission)}
                                        />
                                    ))}
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleOpenAssignModal(role._id)}
                                        aria-label="Assign role"
                                    >
                                        <AddCircleIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Assign Role Modal */}
            <Modal
                open={openAssignModal}
                onClose={handleCloseAssignModal}
                aria-labelledby="assign-role-modal"
                aria-describedby="assign-role-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="assign-role-modal" variant="h6" component="h2">
                        Assign Role
                    </Typography>
                    <TextField
                        select
                        fullWidth
                        label="Select User"
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e.target.value)}
                        sx={{ mt: 2 }}
                    >
                        {users.map((user) => (
                            <MenuItem key={user.uid} value={user.uid}>
                                {user.username}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        onClick={handleAssignRole}
                        disabled={!selectedUser}
                    >
                        Assign
                    </Button>
                </Box>
            </Modal>

            {/* Remove Permission Modal */}
            <Modal
                open={openRemoveModal}
                onClose={handleCloseRemoveModal}
                aria-labelledby="remove-permission-modal"
                aria-describedby="remove-permission-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="remove-permission-modal" variant="h6" component="h2">
                        Remove Permission
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        Are you sure you want to remove the permission for{' '}
                        <strong>{selectedPermission?.username}</strong>?
                    </Typography>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ mt: 2 }}
                        onClick={handleRemovePermission}
                    >
                        Remove
                    </Button>
                </Box>
            </Modal>
        </MainLayout>
    );
};

export default SubjectAllotment;
