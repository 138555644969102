import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { Box, Button, Modal, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import DrawerNav from '../DrawerNav/DrawerNav'

import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { Delete, Edit } from '@mui/icons-material'
import { toast } from 'react-toastify'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

function ExamComponent() {
    const navigate = useNavigate()
    const { id } = useParams()
    const query = useQuery()
    const examName = query.get('name')
    const [apiCalled, setApiCalled] = useState(false)
    const [questionsList, setQuestionsList] = useState([])
    const [openAddQuestionModal, setOpenAddQuestionModal] = useState(false)
    const [openUploadExcelModal, setOpenUploadExcelModal] = useState(false)
    const [questionType, setQuestionType] = useState('multiple_choice')

    const handleImportExcel = () => {
        navigate('/add-excel-questions/' + id + '?name=' + examName)
    }

    const openReorderPage = () => {
        navigate('/reorder-questions/' + id + '?name=' + examName)
    }

    const copyQuizID = () => {
        navigator.clipboard.writeText(id)
            .then(() => {
                toast.info('Quiz ID copied to clipboard', {
                    position: 'bottom-left'
                });
            })
            .catch((err) => {
                console.error('Failed to copy ID to clipboard', err);
            });
    };

    const getTopicsList = async () => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }

            await fetch(`${BASE_URL}/api/admin/v3/exam/${id}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.status === 200) {
                        setQuestionsList(data.data.resourceData.questions)
                        setApiCalled(true)
                        toast.success('Questions loaded successfully!', {
                            position: 'bottom-left'
                        })
                    }
                })
                .catch((error) => {
                    console.log('Error : ', error)
                    setApiCalled(true)
                })
        } catch {
        } finally {
            setApiCalled(true)
        }
    }

    useEffect(() => {
        if (!apiCalled) {
            getTopicsList()
        }
    }, [])

    const handleQuestionTypeChange = (event) => {
        setQuestionType(event.target.value)
    }

    const addQuestionToDatabase = async (questionTypeText, questionText, optionsText, correctOptionText, explanationText) => {
        try {
            const response = await axios.post(`${BASE_URL}/api/admin/v1/question`, {
                examID: id,
                type: questionTypeText,
                question: questionText,
                passage: passageText,
                options: optionsText,
                correctOption: correctOptionText,
                explanation: explanationText,
                imageURL,
                audioURL
            })
            if (response.data.status === 200) {
                resetFields()
                getTopicsList()
                toast.success('Questions added successfully!', {
                    position: 'bottom-left'
                })
            }
            console.log('Question added successfully:', response.data)
        } catch (error) {
            console.error('Error adding question:', error)
        }
    }

    const allowedTypes = ['multiple_choice', 'true_false', 'passage']
    const handleSaveQuestion = async (typeOfQuestion) => {
        if (!allowedTypes.includes(typeOfQuestion)) {
            console.log('Invalid type : ', typeOfQuestion)
        }

        if (typeOfQuestion === 'multiple_choice') {
            const optionsArray = [optionA, optionB, optionC, optionD]
            addQuestionToDatabase(typeOfQuestion, questionText, optionsArray, correctOptionMC, explanation)
        } else if (typeOfQuestion === 'true_false') {
            const optionsArray = ['True', 'False']
            await addQuestionToDatabase(typeOfQuestion, questionText, optionsArray, correctOptionTF, explanation)
        } else if (typeOfQuestion === 'passage') {
            console.log('Questions List : ', questions)
            for (const eachQuestion of questions) {
                await addQuestionToDatabase('passage', eachQuestion.text, eachQuestion.options, eachQuestion.correctOption, eachQuestion.explanation)
            }
        }
        setOpenAddQuestionModal(false)
    }

    const [questionsCount, setQuestionsCount] = useState(1)
    const [questionText, setQuestionText] = useState('')
    const [imageURL, setImageURL] = useState('')
    const [audioURL, setAudioURL] = useState('')
    const [explanation, setExplanation] = useState('')
    const [hint, setHint] = useState('')
    const [optionA, setOptionA] = useState('')
    const [optionB, setOptionB] = useState('')
    const [optionC, setOptionC] = useState('')
    const [optionD, setOptionD] = useState('')
    const [correctOptionMC, setCorrectOptionMC] = useState('')
    const [correctOptionTF, setCorrectOptionTF] = useState('')
    const [passageText, setPassageText] = useState('')
    const [questions, setQuestions] = useState(Array(1).fill({ text: '', options: Array(4).fill(''), correctOption: '', explanation: '' }))

    const resetFields = () => {
        setQuestionsCount(1)
        setQuestionText('')
        setExplanation('')
        setOptionA('')
        setOptionB('')
        setOptionC('')
        setOptionD('')
        setCorrectOptionMC('')
        setCorrectOptionTF('')
        setPassageText('')
        setQuestions(Array(1).fill({ text: '', options: Array(4).fill(''), correctOption: '', explanation: '' }))
        setImageURL('')
        setAudioURL('')
    }

    const handleCorrectOptionChangeMC = (event) => {
        setCorrectOptionMC(event.target.value)
    }

    const getOptionAlphabet = (num) => {
        if (num === 0) return 'A'
        if (num === 1) return 'B'
        if (num === 2) return 'C'
        if (num === 3) return 'D'
    }

    const renderQuestionText = (text) => {
        // Replace new lines with <br />
        let formattedText = text.replace(/\n/g, '<br />')

        // Replace sequences for underline, bold, and italic
        formattedText = formattedText
            .replace(/-\*([^*]+)\*-/g, '<u>$1</u>') // -*text*- for underline
            .replace(/\*\*([^*]+)\*\*/g, '<b>$1</b>') // **text** for bold
            .replace(/\*([^*]+)\*/g, '<i>$1</i>') // *text* for italic

        return <div dangerouslySetInnerHTML={{ __html: formattedText }} />
    }

    const handleEditQuestion = (id) => {
        navigate('/edit-question?id=' + id)
    }

    const handleDeleteQuestion = (id, question) => {
        navigate('/delete-question?id=' + id + `&question=${question}`)
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <div className='pb-48 p-4'>
                        <div className='flex justify-between items-center'>
                            <h1 className='text-4xl font-bold'>{examName} </h1>
                            <div className='space-x-2'>
                                <Button variant='outlined' color='info' onClick={() => copyQuizID()}>
                                    Copy Quiz ID
                                </Button>
                                <Button variant='outlined' color='info' onClick={() => openReorderPage()}>
                                    Reorder Questions
                                </Button>
                                <Button variant='contained' color='info' onClick={() => handleImportExcel()}>
                                    Import Excel
                                </Button>
                                <Button variant='contained' color='success' onClick={() => setOpenAddQuestionModal(true)}>
                                    Add Question
                                </Button>
                            </div>
                        </div>
                        <div className='pt-4'>
                            <div className='font-bold'>Questions</div>
                            {questionsList.length > 0 && (
                                <>
                                    {questionsList.map((question, index) => {
                                        return (
                                            <div key={index} className='my-4'>
                                                <div className='mb-2'>
                                                    {question.audioURL && (
                                                        <div className='mb-8'>
                                                            <audio controls>
                                                                <source src={question.audioURL} type='audio/mpeg' />
                                                                Your browser does not support the audio element.
                                                            </audio>
                                                        </div>
                                                    )}
                                                    {question.imageURL && (
                                                        <div className='mb-8'>
                                                            <img className='h-64 rounded-lg' src={question.imageURL} alt='Question' />
                                                        </div>
                                                    )}
                                                    <div className='font-bold'>
                                                        Question {index + 1}
                                                        <Edit className='cursor-pointer' onClick={() => handleEditQuestion(question._id)} sx={{ marginLeft: '10px', marginTop: '-5px', fontSize: '20px' }} />
                                                        <Delete className='cursor-pointer' onClick={() => handleDeleteQuestion(question._id, question.question)} sx={{ marginLeft: '5px', marginTop: '-5px', fontSize: '20px' }} />
                                                    </div>
                                                    {
                                                        question.question && <div><span className='font-bold'></span> {question.question}</div>
                                                    }
                                                </div>
                                                <div>
                                                    {question.options &&
                                                        question.options.map((option, optionIndex) => (
                                                            <span key={optionIndex} className={`mr-4 ${option === question.correctOption ? 'text-green-700 font-bold' : ''}`}>
                                                                {getOptionAlphabet(optionIndex)}. {option}
                                                            </span>
                                                        ))}
                                                </div>
                                                {
                                                    question.hint && <div className='mt-4'><span className='font-bold'>Hint:</span> {question.hint}</div>
                                                }
                                                {
                                                    question.explanation && <div><span className='font-bold'>Explanation:</span> {question.explanation}</div>
                                                }
                                                <hr className='my-4' />
                                            </div>
                                        )
                                    })}
                                </>
                            )}
                        </div>
                    </div>
                </Box>
            </Box>
            <Modal open={openAddQuestionModal} onClose={() => setOpenAddQuestionModal(false)} aria-labelledby='add-question-modal' aria-describedby='add-question-modal-description'>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '70%',
                        maxHeight: '90%',
                        overflowY: 'auto', // Adding overflow-y to enable scrolling
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4
                    }}>
                    <h2 id='add-question-modal'>Add Question</h2>
                    <FormControl fullWidth variant='outlined' margin='normal'>
                        <InputLabel id='question-type-label'>Question Type</InputLabel>
                        <Select labelId='question-type-label' id='question-type' value={questionType} onChange={handleQuestionTypeChange} label='Question Type'>
                            <MenuItem value='multiple_choice'>Multiple Choice</MenuItem>
                        </Select>
                    </FormControl>
                    {questionType === 'multiple_choice' && (
                        <>
                            <TextField id='question-text' multiline label='Question' fullWidth variant='outlined' margin='normal' value={questionText} onChange={(e) => setQuestionText(e.target.value)} />
                            <TextField id='image-url' label='Image URL' fullWidth variant='outlined' margin='normal' value={imageURL} onChange={(e) => setImageURL(e.target.value)} />
                            <TextField id='audio-url' label='Audio URL' fullWidth variant='outlined' margin='normal' value={audioURL} onChange={(e) => setAudioURL(e.target.value)} />
                            <TextField id='option-a' label='Option A' fullWidth variant='outlined' margin='normal' value={optionA} onChange={(e) => setOptionA(e.target.value)} />
                            <TextField id='option-b' label='Option B' fullWidth variant='outlined' margin='normal' value={optionB} onChange={(e) => setOptionB(e.target.value)} />
                            <TextField id='option-c' label='Option C' fullWidth variant='outlined' margin='normal' value={optionC} onChange={(e) => setOptionC(e.target.value)} />
                            <TextField id='option-d' label='Option D' fullWidth variant='outlined' margin='normal' value={optionD} onChange={(e) => setOptionD(e.target.value)} />
                            <FormControl fullWidth variant='outlined' margin='normal'>
                                <InputLabel id='correct-option-label'>Correct Option</InputLabel>
                                <Select labelId='correct-option-label' id='correct-option' value={correctOptionMC} onChange={handleCorrectOptionChangeMC} label='Correct Option'>
                                    <MenuItem value={optionA}>{optionA}</MenuItem>
                                    <MenuItem value={optionB}>{optionB}</MenuItem>
                                    <MenuItem value={optionC}>{optionC}</MenuItem>
                                    <MenuItem value={optionD}>{optionD}</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField id='explanation' label='Explanation' fullWidth variant='outlined' margin='normal' value={explanation} onChange={(e) => setExplanation(e.target.value)} />
                            <TextField id='hint' label='Hint' fullWidth variant='outlined' margin='normal' value={hint} onChange={(e) => setHint(e.target.value)} />
                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                <Button variant='contained' color='primary' sx={{ mr: 1 }} onClick={() => setOpenAddQuestionModal(false)}>
                                    Cancel
                                </Button>
                                <Button variant='contained' color='success' onClick={() => handleSaveQuestion('multiple_choice')}>
                                    Save
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Modal>

            <Modal open={openUploadExcelModal} onClose={() => setOpenUploadExcelModal(false)} aria-labelledby='upload-excel-modal' aria-describedby='upload-excel-modal-description'>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4
                    }}>
                    <h2 id='upload-excel-modal'>Upload Excel File</h2>
                    <p id='upload-excel-modal-description'>Please select an Excel file to upload:</p>
                    <input type='file' accept='.xlsx,.xls' />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='contained' color='primary' sx={{ mr: 1 }} onClick={() => setOpenUploadExcelModal(false)}>
                            Cancel
                        </Button>
                        <Button variant='contained' color='success' onClick={() => setOpenUploadExcelModal(false)}>
                            Upload
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default ExamComponent
